import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { toast } from "~/components/toast";
import { getAuthQuery } from "~/domains/auth/queries/useGetAuthQuery";
import { getPlanQuery } from "~/domains/payment/queries/useGetPlansQuery";
import { getActiveTestsQuery } from "~/domains/test/queries/getActiveTests";
import { openPanel } from "~/libs/op";

export default function useAuthHandler() {
  const router = useRouter();
  const { token, initToken } = useToken();
  const loading = ref(true);
  const queryClient = useQueryClient();
  const isApp = useIsApp()
  const push = usePushNotification()


  const { mutateAsync: mutateAuth } = useMutation({
    mutationFn: async () => {
      const config = useRuntimeConfig();
      const authStore = useAuthStore();
      const client = GetApiClient(
        config.public.apiURL as string,
        token.value ?? "",
      );

      await useDevice().init()
      await push.init()

      // Check if the user is authenticated
      try {
        await queryClient.fetchQuery(getAuthQuery())
      } catch (error) {
        authStore.logout();
        await router.replace("/");
        return;
      }

      const data = await queryClient.fetchQuery(getAuthQuery())

      if (data.user.type !== "user") {
        authStore.logout();
        toast.error("Entre em uma conta de estudante");
        await router.replace("/");
        return;
      }

      // Identify user
      const nameparts = data.user.fullname.split(' ')
      openPanel.identify({
        profileId: data.user.id,
        email: data.user.email,
        firstName: nameparts[0] ?? '',
        lastName: nameparts.length > 1 ? nameparts.pop() : '',
      })

      if (isApp) {
        await usePurchases().configure(data.user.id);
      }

      const [subjectsRes] = await Promise.all([
        client.student.subjects.$get(),
        queryClient.prefetchQuery(getPlanQuery()),
        queryClient.prefetchQuery(getActiveTestsQuery()),
      ])

      const subjectData = await subjectsRes.json();

      authStore.login(
        data.user as any,
        data.session as any,
        data.student as any,
      );

      authStore.$patch({
        studentStreak: data.studentStreak ?? {
          streak: 0,
          weeks: []
        },
        totalQuestionsToday: data.studentQuestionsToday,
        subjectsStars: data.subjectsStars,
        subjects: subjectData,
      });

      // Check onboarding
      if (!data.user.onboardingCompleted) {
        await router.replace("/onboarding?etapa=" + data.user.onboardingStep);

        setTimeout(() => {
          loading.value = false;
        }, 500);
        return;
      }

      loading.value = false;
    },
  });

  const checkAuth = async () => {
    await initToken()

    if (!token.value) {
      loading.value = false;
      router.push("/");
      return;
    }

    await mutateAuth();
  }

  return {
    loading,
    checkAuth
  };
}
